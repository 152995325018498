<template>
  <div class="card">
    <div class="card-body">
      <!-- Spline Area chart -->
      <h4 class="card-title">Month on Month Trend</h4>
      <VueApexCharts
        class="apex-charts"
        height="350"
        type="area"
        dir="ltr"
        :series="series"
        :options="chartOptions"
        :key="key"
      ></VueApexCharts>
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import VueApexCharts from "vue-apexcharts";
import filterMixins from "../../../../mixins/filterData";
import moment from "moment";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    VueApexCharts,
  },
  data() {
    return {
      title: "Videos",
      series: [
        {
          name: "Views",
          data: [],
        },
        {
          name: "Viewers",
          data: [],
        },
      ],
      chart: {
        toolbar: {
          show: false,
        },
      },
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        colors: ["#556ee6", "#34c38f"],
        xaxis: {
          type: "date",
          categories: [],
        },
      },
      key: 0,
      loading: true,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getMonthlyTrend();
      },
    },
  },
  created() {},
  methods: {
    init() {},
    getFormatedDate(date, type = "date") {
      if (type == "date") {
        return moment(date).format("DD MMMM");
      } else {
        return moment(date).format("MMMM");
      }
    },
    async getMonthlyTrend() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/videos/monthlyTrend",
          {
            params: searchfiltersParams,
          }
        );
        this.series[0].data = response.data.month_on_month_trend.map(
          (n) => n.total_views
        );
        this.series[1].data = response.data.month_on_month_trend.map(
          (n) => n.total_viewers
        );
        this.chartOptions.xaxis.categories =
          response.data.month_on_month_trend.map((n) =>
            this.getFormatedDate(n.month_year, "month")
          );

        this.key += 1;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getMonthlyTrend();
  },
};
</script>
